module.exports = [{
      plugin: require('/Users/ken/Documents/GitHub/legal-site/node_modules/@wapps/gatsby-plugin-material-ui/gatsby-browser'),
      options: {"plugins":[],"theme":{"palette":{"primary":{"main":"#405766"}},"typography":{"fontFamily":"PT serif,Gill Sans,Gill Sans MT,Lato,sans-serif"}},"productionPrefix":"c"},
    },{
      plugin: require('/Users/ken/Documents/GitHub/legal-site/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/ken/Documents/GitHub/legal-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
