// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/ken/Documents/GitHub/legal-site/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/ken/Documents/GitHub/legal-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/ken/Documents/GitHub/legal-site/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bankruptcy-js": () => import("/Users/ken/Documents/GitHub/legal-site/src/pages/bankruptcy.js" /* webpackChunkName: "component---src-pages-bankruptcy-js" */),
  "component---src-pages-contact-js": () => import("/Users/ken/Documents/GitHub/legal-site/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-family-law-js": () => import("/Users/ken/Documents/GitHub/legal-site/src/pages/family-law.js" /* webpackChunkName: "component---src-pages-family-law-js" */),
  "component---src-pages-general-practice-js": () => import("/Users/ken/Documents/GitHub/legal-site/src/pages/general-practice.js" /* webpackChunkName: "component---src-pages-general-practice-js" */),
  "component---src-pages-index-js": () => import("/Users/ken/Documents/GitHub/legal-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("/Users/ken/Documents/GitHub/legal-site/src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-real-estate-js": () => import("/Users/ken/Documents/GitHub/legal-site/src/pages/real-estate.js" /* webpackChunkName: "component---src-pages-real-estate-js" */)
}

exports.data = () => import("/Users/ken/Documents/GitHub/legal-site/.cache/data.json")

